<template>
  <div class="privacy">
    <img src="/imgs/privacy/top.png" alt="">
    <div class="text">
      <div>无界SaaS官方网站（以下简称“本网站”）隐私权保护申明，系本网站保护个人隐私的承诺。鉴于Internet网络的特性，本网站会与您直接或间接地互动并因此产生关系，故特此说明本网站对您个人信息所采取的收集、使用和保护政策，请您务必仔细阅读：</div>
      <h3>一、搜集、分拣信息</h3>
      <div class="">当您浏览、阅读或下载本网站的信息时，网站会自动搜集和分拣到您的信息（互联网域名、用户使用的浏览器和操作系统类型、访问时间、访问页面），这些信息不会被用来确定您的身份。</div>
      <h3>二、信息安全</h3>
      <div class="">本网站将使用相应的技术，对您所提供的个人资料进行严格的管理和保护，防止个人资料丢失、被盗用或遭窜改。为保护个人信息，必要时本网站会委托专业技术人员对所述资料进行相应处理，并将处理安排及时通知您，若您未在通知规定的时间内主动明示反对，本网站将推定您已同意。但处理过后，您仍然有权请求停止电脑处理。</div>
      <h3>三、用户权利</h3>
      <div class="">在本网站上，您对自己拥有的个人资料享有以下权利：</div>
      <div class="">（一）随时查询和请求阅览；</div>
      <div class="">（二）随时请求补充或更正；</div>
      <div class="">（三）随时请求删除；</div>
      <div class="">（四）请求停止电脑处理及利用。</div>
      <h3>四、限制利用原则</h3>
      <div class="">本网站只有在下列条件下，方可对收集的个人资料进行必要范围以外的使用：</div>
      <div class="">（一）已取得您的书面同意；</div>
      <div class="">（二）为免除您在生命、身体或财产方面遇到的急迫危险；</div>
      <div class="">（三）为防止他人权益遭受重大危害；</div>
      <div class="">（四）为增进公共利益，且无损于您的重大利益。</div>
      <h3>五、披露个人资料</h3>
      <div class="">当政府机关依照法定程序要求本网站披露个人资料时，本网站将根据其要求或为了公共安全的目的提供个人资料；这种情况下的任何披露，本网站均得免责。</div>
      <h3>六、未成年人隐私权的保护</h3>
      <div class="">（一） 本网站通过计算机技术对未成年人的个人资料给予保护，在此郑重声明：任何16岁以下的未成年人参加网上活动都应事先得到家长或其法定监护人（以下简称“监护人”）的可经查证的同意。</div>
      <div class="">（二） 监护人应承担保护未成年人在网络环境下的隐私权的首要责任。</div>
      <div class="">（三） 本网站认为有必要收集未成年人的个人资料时，仅为回复未成人特定要求的目的，一但回复完毕即从记录中删除，不保留或使用这些资料，并保证不会要求未成年人提供额外的个人资料，作为允许其参与网上活动的条件。</div>
      <div class="">（四） 未经监护人的同意，本网站不会使用未成年人的个人资料，亦不会向任何第三方披露或传送。本网站若收集监护人或未成年人的姓名或其它网络通讯资料，仅是为满足监护人的要求；若监护人在一定时间放弃这一要求时，本网站会主动从记录中删除此类资料。</div>
      <div class="">（五） 若经监护人的同意，本网站可对未成年人的个人资料进行收集，并向监护人提供：</div>
      <div class="">1、审视其子女或被监护人收集资料的机会；</div>
      <div class="">2、拒绝其子女或被监护人的个人资料被进一步收集或利用的机会；</div>
      <div class="">3、变更或删除其子女或被监护人个人资料的方式。</div>
      <div class="">（六） 监护人有权拒绝本网站与其子女或被监护人做进一步的联络。</div>
      <h3>七、免责</h3>
      <div class="">除上述第五条规定属免责外，发生下列情况时本网站也不承担任何责任：</div>
      <div class="">（一）任何黑客政击、计算机病毒侵入或发作、政府部门管制和其他不可抗力等造成的个人资料泄露、丢失、被盗用或被窜改等。</div>
      <div class="">（二）因与本网站链接的其它网站造成个人资料泄露及由此而导致的任何法律争议和后果。</div>
      <div class="">本声明自更新之日起生效最近的更新日期：2023 年 4 月</div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.privacy {
  line-height: 0.15rem;
  padding-bottom: 0.3rem;
  img {
    margin: 0.3rem 0;
  }
  .text {
    text-align: left;
  }
}
</style>
